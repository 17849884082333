import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { contactStore } from "../../services/contacts/ContactStore";
import { groupStore } from "../../services/groups/GroupStore";
import { messageStore } from "../../services/messages/MessageStore";
import useAuth from "../../services/user/AuthHook";

export default function RefreshComponent() {
  const loggedInUser = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refreshStores();
  });

  function refreshStores() {
    if (loggedInUser?.username) {
      contactStore
        .fetchContacts()
        .then(() => {
          groupStore
            .fetchGroups()
            .then(() => {
              messageStore.fetchMessages().catch((error) => {
                enqueueSnackbar(
                  "Something went wrong fetching messages: " + error,
                  {
                    variant: "error",
                  }
                );
              });
            })
            .catch((error) => {
              enqueueSnackbar(
                "Something went wrong fetching groups: " + error,
                {
                  variant: "error",
                }
              );
            });
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong fetching contacts: " + error, {
            variant: "error",
          });
        });
    }
  }

  return <></>;
}
