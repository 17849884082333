import { api } from "../api/Axios";
import { Message, MessageRequest } from "./Message";

export default class MessageService {
  static async fetchAllMessages(): Promise<Message[]> {
    return (await api.get("/accounts/me/messages")).data;
  }

  static async fetchMessage(id: number): Promise<Message> {
    return (await api.get("/accounts/me/messages/" + id)).data;
  }

  static async sendMessage(newMessage: MessageRequest): Promise<Message> {
    return (await api.post("/accounts/me/messages", newMessage)).data;
  }
}
