import {
  Button,
  ButtonGroup,
  createStyles,
  Grid,
  Icon,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { contactStore } from "../../services/contacts/ContactStore";
import { Group } from "../../services/groups/Group";
import { groupStore } from "../../services/groups/GroupStore";
import ContactsDraggableList from "../contacts/ContactsDraggableList";
import GroupCard from "./GroupCard";

const useStyles = makeStyles(
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    buttons: {
      margin: "1.5rem 0.5rem",
    },
    content: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
    },
    cards: {
      flex: 9,
      overflow: "scroll",
      overflowX: "hidden",
      flexWrap: "nowrap",
    },
    contacts: {
      flex: 3,
      marginLeft: "0.5rem"
    }
  })
);

export default function GroupGrid(props: {
  groups: Group[];
  openMessageDialog: (groups: Group[]) => void;
  onOpenGroupDialog: (groupToEdit?: Group) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  function onDragEnd(result: DropResult) {
    const { destination, source, draggableId } = result;
    if (destination && source.droppableId !== destination.droppableId) {
      addContactToGroup(+draggableId, +destination.droppableId);
    }
  }

  function addContactToGroup(contactId: number, groupId: number) {
    let contact = contactStore.getContactById(contactId)!;
    let group = groupStore.getGroupById(groupId)!;

    if (!group.members.some((member) => member.id === contact.id)) {
      group?.members.push(contact!);
      groupStore.saveGroup(group);
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.buttons}>
        <ButtonGroup>
          <Button
            onClick={() => props.onOpenGroupDialog()}
            endIcon={<Icon>add</Icon>}
          >
            {t("groups.grid.new")}
          </Button>
        </ButtonGroup>
      </div>
      <div className={classes.content}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={classes.cards}>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              {props.groups.map((group, index) => {
                return (
                  <GroupCard
                    key={`groupcard${group}${index}`}
                    group={group}
                    index={index}
                    openMessageDialog={(group: Group) =>
                      props.openMessageDialog([group])
                    }
                    openGroupDialog={(group: Group) =>
                      props.onOpenGroupDialog(group)
                    }
                  ></GroupCard>
                );
              })}
            </Grid>
          </div >
          <div className={classes.contacts}>
            <ContactsDraggableList />
          </div>
        </DragDropContext >
      </div>
    </div >
  );
}
