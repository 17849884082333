import { BehaviorSubject } from "rxjs";
import { User } from "./User";
import UserService from "./UserService";
import jwtDecode from "jwt-decode";

export class UserStore {
  private readonly _users = new BehaviorSubject<User[]>([]);
  private readonly _loggedInUser = new BehaviorSubject<User>({} as User);

  readonly users$ = this._users.asObservable();
  readonly loggedInUser$ = this._loggedInUser.asObservable();

  private get users(): User[] {
    return this._users.getValue();
  }

  private set users(users: User[]) {
    this._users.next(users);
  }

  private get loggedInUser(): User {
    return this._loggedInUser.getValue();
  }

  private set loggedInUser(user: User) {
    this._loggedInUser.next(user);
  }

  constructor() {
    this.checkLoggedIn();
    this.fetchUsers();
  }

  fetchUsers() {
    UserService.fetchAllUsers().then((users) => {
      this.users = users;
    });
  }

  checkLoggedIn() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decoded = jwtDecode<{ username: string }>(token);
      const user: User = { username: decoded.username };
      this.loggedInUser = user;
    }
  }

  async loginUser(username: string, password: string): Promise<void> {
    return UserService.loginUser({ username, password }).then((result) => {
      this.loggedInUser = { username };
      localStorage.setItem("accessToken", result.data);
    });
  }

  async logout(): Promise<any> {
    return UserService.logout().finally(() => {
      this.loggedInUser = {} as User;
      localStorage.removeItem("accessToken");
    });
  }

  async registerNewUser(newUser: User) {
    return true;
  }
}

export const userStore = new UserStore();
