import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function PrivacyDialog(props: {
  onClose: (accept: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={true}
      onClose={() => {
        props.onClose(false);
      }}
    >
      <DialogTitle id="alert-dialog-title">{t("privacy.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>{t("privacy.dialog.main")}</p>
          <p>{t("privacy.dialog.cancel")}</p>
          <p>
            {t("privacy.dialog.site") + " "}
            <a
              href="https://www.fonsie.app/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              {t("privacy.dialog.link")}
            </a>
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose(false);
          }}
        >
          {t("cancel")}
        </Button>
        <Button onClick={() => props.onClose(true)} color="secondary" autoFocus>
          {t("agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
