import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  TextField,
} from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { contactStore } from "../../services/contacts/ContactStore";
import { Contact } from "../../services/contacts/Contact";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import PrivacyDialog from "../privacy/PrivacyAgreementDialog";
import { ContactPreference } from "../message/ContactPreference";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      display: "flex",
      flexDirection: "column",
      "& .MuiFormControl-root": {
        margin: theme.spacing(2),
        flex: 1,
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
    multiselect: {
      margin: "0 1rem",
    },
  })
);

/*
  This gives a warning with React Strictmode, should be fixed in version 5.0.
  Which hopefully realeases soon.
  https://github.com/mui-org/material-ui/milestones
*/
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactDialog(props: {
  close: () => void;
  contact?: Contact;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    firstname: props.contact?.first_name || "",
    lastname: props.contact?.last_name || "",
    email:
      (props.contact?.preference &&
        props.contact?.preference.includes(ContactPreference.EMAIL)) ||
      false,
    phone:
      (props.contact?.preference &&
        props.contact?.preference.includes(ContactPreference.CALL)) ||
      false,
    text:
      (props.contact?.preference &&
        props.contact?.preference.includes(ContactPreference.SMS)) ||
      false,
    emailAddress: props.contact?.email || "",
    phoneNumber: props.contact?.telephone || "",
  });

  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  function handleAgreeDialog() {
    setPrivacyAgree(true);
    setPrivacyDialogOpen(false);
  }

  const { firstname, lastname, email, phone, text, emailAddress, phoneNumber } =
    state;

  const handleSaveContact = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let preferredMethods: string[] = [];
    email && preferredMethods.push(ContactPreference.EMAIL);
    phone && preferredMethods.push(ContactPreference.CALL);
    text && preferredMethods.push(ContactPreference.SMS);
    let newContact: Contact = {
      id: props.contact?.id,
      first_name: firstname,
      last_name: lastname,
      preference: preferredMethods,
      email: emailAddress,
      telephone: phoneNumber,
    };
    contactStore
      .saveContact(newContact)
      .then(() => {
        enqueueSnackbar(t("contacts.dialog.save_message", { name: newContact.first_name }), {
          variant: "success",
        });
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(`${t("snackbar.error_short")}: \n ${error}`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={() => props.close()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={() => props.close()}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.contact?.first_name && props.contact.last_name
                ? t("contacts.dialog.title.update", { name: `${props.contact.first_name} ${props.contact.last_name}` })
                : t("contacts.dialog.title.create")}
            </Typography>
            <Button
              form="contact-form"
              type="submit"
              color="inherit"
              endIcon={<Icon>save</Icon>}
            >
              {t("contacts.dialog.save")}
            </Button>
          </Toolbar>
        </AppBar>
        <form
          id="contact-form"
          className={classes.root}
          onSubmit={handleSaveContact}
        >
          <TextField
            required={true}
            id="outlined-basic"
            label={t("contacts.firstname")}
            variant="outlined"
            name="firstname"
            value={firstname}
            onChange={handleTextChange}
          />
          <TextField
            required={true}
            id="outlined-basic"
            label={t("contacts.lastname")}
            variant="outlined"
            name="lastname"
            value={lastname}
            onChange={handleTextChange}
          />
          <div className={classes.multiselect}>
            <FormControl
              required={true}
              component="fieldset"
              className={classes.formControl}
            >
              <FormLabel component="legend">
                {t("contacts.dialog.methods.title")}
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={email}
                      onChange={handleCheckChange}
                      name="email"
                    />
                  }
                  label={t("contacts.dialog.methods.mail")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={phone}
                      onChange={handleCheckChange}
                      name="phone"
                    />
                  }
                  label={t("contacts.dialog.methods.phone")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={text}
                      onChange={handleCheckChange}
                      name="text"
                    />
                  }
                  label={t("contacts.dialog.methods.text")}
                />
              </FormGroup>
              <FormHelperText>
                {t("contacts.dialog.methods.select")}
              </FormHelperText>
            </FormControl>
          </div>
          <TextField
            required={email}
            id="outlined-basic"
            label={t("contacts.dialog.mail")}
            variant="outlined"
            name="emailAddress"
            value={emailAddress}
            onChange={handleTextChange}
          />
          <TextField
            required={phone || text}
            id="outlined-basic"
            label={t("contacts.dialog.phone")}
            variant="outlined"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handleTextChange}
          />
          <div className={classes.multiselect}>
            <FormControl
              required
              component="fieldset"
              className={classes.formControl}
            >
              <FormLabel component="legend">{t("privacy.title")}</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyAgree}
                      required
                      onChange={() => {
                        setPrivacyAgree(!privacyAgree);
                      }}
                      name="privacyAgree"
                    />
                  }
                  label={t("privacy.accept")}
                />
                <Typography
                  variant="caption"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPrivacyDialogOpen(true);
                  }}
                >
                  {t("privacy.read")} <b>{t("privacy.here")}</b>
                </Typography>
              </FormGroup>
            </FormControl>
          </div>
        </form>
      </Dialog>

      {privacyDialogOpen && (
        <PrivacyDialog
          onClose={(accept: boolean) => {
            if (accept) {
              handleAgreeDialog();
            }
            setPrivacyDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}
