import { GridListTile } from "@material-ui/core";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Icon,
  makeStyles,
  GridList,
} from "@material-ui/core";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Group } from "../../services/groups/Group";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 5,
  },
  draggingOver: {
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.2)",
    background: "rgb(219, 238, 255)",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 22,
  },
  pos: {
    marginBottom: 12,
  },
  buttons: {
    justifyContent: "space-between",
  },
});

export default function GroupCard(props: {
  group: Group;
  index: number;
  openMessageDialog: (group: Group) => void;
  openGroupDialog: (group: Group) => void;
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Droppable
      key={props.index + props.group.name}
      droppableId={props.group.id!.toString()}
    >
      {(provided, snapshot) => (
        <Grid
          innerRef={provided.innerRef}
          {...provided.droppableProps}
          key={props.index + props.group.name}
          item
        >
          <Card
            className={`${classes.root} ${snapshot.isDraggingOver && classes.draggingOver}`}
            variant="outlined"
          >
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {props.group.name}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                {t("groups.grid.card.title")}
              </Typography>
              <GridList cellHeight={20} cols={3}>
                {props.group.members.map((member, index) => {
                  return (
                    <GridListTile key={`contactlist${member.id!}${index}`}>
                      <Typography>{`${member.last_name}, ${member.first_name}`}</Typography>
                    </GridListTile>
                  );
                })}
              </GridList>
            </CardContent>
            <CardActions className={classes.buttons}>
              <Button
                size="small"
                color="primary"
                endIcon={<Icon>send</Icon>}
                onClick={() => props.openMessageDialog(props.group)}
              >
                {t("groups.grid.card.send")}
              </Button>
              <Button
                size="small"
                endIcon={<Icon>edit</Icon>}
                onClick={() => props.openGroupDialog(props.group)}
              >
                {t("groups.grid.card.edit")}
              </Button>
            </CardActions>
          </Card>
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  );
}
