import {
  createStyles,
  Grid,
  Icon,
  InputAdornment,
  InputBase,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useState } from "react";
import MessageDialog from "../message/MessageDialog";
import useGroups from "../../services/groups/GroupHook";
import { Group } from "../../services/groups/Group";
import GroupDialog from "./GroupDialog";
import GroupGrid from "./GroupGrid";
import GroupList from "./GroupList";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Message } from "../../services/messages/Message";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "70%",
    },
    buttons: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    input: {
      marginLeft: theme.spacing(1),
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: "2px",
      width: "100%",
      margin: "0.5rem",
      padding: "0.5rem",
    },
  })
);

export default function Groups() {
  const { groups } = useGroups();
  const { t } = useTranslation();
  const classes = useStyles();
  const [messageDiaglogOpen, setMessageDialogOpen] = useState(false);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [gridView, setGridView] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([] as Group[]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGroups, setFilteredGroups] = useState([] as Group[]);

  function openMessageDialog(groups: Group[]) {
    setSelectedGroups(groups);
    setMessageDialogOpen(true);
  }

  function handleOpenGroupDialog(group?: Group) {
    if (!group) {
      setSelectedGroups([]);
    } else {
      setSelectedGroups([group]);
    }

    setGroupDialogOpen(true);
  }

  function handleGridToggle(
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    gridView: boolean
  ) {
    setGridView(gridView);
  }

  function handleSearchQueryUpdate(event: {
    target: { value: React.SetStateAction<string> };
  }) {
    let newQuery: string = event.target.value.toString();
    setSearchQuery(newQuery);
    if (newQuery.length > 2) {
      let newFilteredGroups = groups.filter((group) => {
        return group.name.toLowerCase().includes(newQuery.toLowerCase());
      });
      setFilteredGroups(newFilteredGroups);
    } else {
      setFilteredGroups([]);
    }
  }

  return (
    <div className={classes.container}>
      <h1>{t("groups.title")}</h1>
      <div className={classes.buttons}>
        <Grid container>
          <InputBase
            className={classes.input}
            placeholder={t("groups.search")}
            inputProps={{ "aria-label": "search groups" }}
            value={searchQuery}
            onChange={handleSearchQueryUpdate}
            type="search"
            startAdornment={
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            }
            endAdornment={
              searchQuery.length > 2 &&
              filteredGroups.length === 0 && (
                <InputAdornment position="end" title="No results">
                  <Icon>error-outline</Icon>
                </InputAdornment>
              )
            }
            style={{
              color:
                searchQuery.length > 2 && filteredGroups.length === 0
                  ? "red"
                  : "black",
            }}
          />
        </Grid>

        <ToggleButtonGroup
          value={gridView}
          exclusive
          onChange={handleGridToggle}
        >
          <ToggleButton value={true}>
            Grid
            <Icon>view_stream</Icon>
          </ToggleButton>
          <ToggleButton value={false}>
            List
            <Icon>view_list</Icon>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {gridView ? (
        <GroupGrid
          groups={filteredGroups.length < 1 ? groups : filteredGroups}
          openMessageDialog={openMessageDialog}
          onOpenGroupDialog={handleOpenGroupDialog}
        />
      ) : (
        <GroupList
          groups={filteredGroups.length < 1 ? groups : filteredGroups}
          openMessageDialog={openMessageDialog}
          onOpenGroupDialog={handleOpenGroupDialog}
        />
      )}
      {messageDiaglogOpen && (
        <MessageDialog
          groups={selectedGroups}
          close={() => setMessageDialogOpen(false)}
          send={(message: Message) => setMessageDialogOpen(false)}
        />
      )}

      {groupDialogOpen && (
        <GroupDialog
          group={selectedGroups[0]}
          close={() => setGroupDialogOpen(false)}
        />
      )}
    </div>
  );
}
