import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Group } from "../../services/groups/Group";
import {
  Message,
  MessageRequest,
  Recipient,
} from "../../services/messages/Message";
import ContactSelector from "./ContactSelector";
import { Contact } from "../../services/contacts/Contact";
import { messageStore } from "../../services/messages/MessageStore";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ContactPreference } from "./ContactPreference";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    contacts: {
      padding: "1rem",
      border: "1px solid rgba(0,0,0,0.3)",
      borderRadius: "5px"
    },
    chip: {
      margin: "0.5rem",
    },
    root: {
      display: "flex",
      padding: "2rem",
      flexDirection: "column",
      "& .MuiFormControl-root": {
        margin: theme.spacing(2),
        flex: 1,
      },
    },
  })
);

/* 
  This gives a warning with React Strictmode, should be fixed in version 5.0.
  Which hopefully realeases soon.
  https://github.com/mui-org/material-ui/milestones
*/
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MessageDialog(props: {
  groups?: Group[];
  close: Function;
  send: (message: Message) => void;
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [state, setState] = useState({
    body: "",
    subject: "",
    voice: "",
    language: "",
    contacts: (props.groups && prepareContacts(props.groups)) || [],
    recipients: (props.groups && initRecipients(prepareContacts(props.groups))) || [],
  });
  const [sendDialogOpen, setSendDialogOpen] = useState(false);

  function prepareContacts(groups: Group[]): Contact[] {
    let contacts: Contact[] = [];
    let rawContacts = groups.flatMap((group) => group.members);
    rawContacts.forEach((rawContact) => {
      contacts.map((contact) => contact.id).indexOf(rawContact.id) === -1 &&
        contacts.push(rawContact);
    });
    return Array.from(contacts);
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleVoiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState({ ...state, voice: event.target.value as string });
  };
  const handleLanguageChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setState({ ...state, language: event.target.value as string });
  };
  const handleRecipientUpdate = (recipients: Recipient[]) => {
    setState({ ...state, recipients: recipients });
  };

  const { body, subject, voice, language, recipients, contacts } = state;

  function sendMessage() {
    setSendDialogOpen(false);
    let emailIds: number[] = [];
    let smsIds: number[] = [];
    let callIds: number[] = [];
    recipients.forEach((recipient) => {
      if (recipient.method === ContactPreference.EMAIL) {
        emailIds.push(recipient.contact_id);
      }
      if (recipient.method === ContactPreference.SMS) {
        smsIds.push(recipient.contact_id);
      }
      if (recipient.method === ContactPreference.CALL) {
        callIds.push(recipient.contact_id);
      }
    });
    let message: MessageRequest = {
      email:
        (emailIds.length > 0 && {
          from: "fonsie@info.app",
          to: emailIds,
          body: body,
          subject: subject,
        }) ||
        undefined,
      sms:
        (smsIds.length > 0 && {
          from: "Fonsie",
          to: smsIds,
          body: body,
        }) ||
        undefined,
      call:
        (callIds.length > 0 && {
          from: "Fonsie",
          to: callIds,
          body: body,
          voice: voice,
          language: language,
          repeat: 2,
        }) ||
        undefined,
    };
    messageStore
      .sendMessage(message)
      .then(() => {
        enqueueSnackbar(t("message.snackbar.sent"), { variant: "success" });
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(`${t("snackbar.error_short")}: ${error}`, { variant: "error" });
      });
  }

  function initRecipients(contacts: Contact[]): Recipient[] {
    let recipients: Recipient[] = [];

    contacts.forEach((contact) => {
      contact.preference.forEach((method) => {
        recipients.push({
          contact_id: contact.id,
          method: method,
        } as Recipient);
      });
    });

    return recipients;
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={() => props.close()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={() => props.close()}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.groups ? t("message.dialog.title", { name: props.groups.map((group) => group.name).join(", ") }) : t("message.dialog.title_empty")}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              endIcon={<Icon>send</Icon>}
              onClick={() => {
                setSendDialogOpen(true);
              }}
            >
              {t("message.dialog.send")}
            </Button>
          </Toolbar>
        </AppBar>
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl required={true}>
            <ContactSelector
              contacts={contacts}
              onUpdate={handleRecipientUpdate}
            />
          </FormControl>
          {/* Don't know why the width needs to be exactly 99% to fit... */}
          <Grid container spacing={2} style={{ width: "99%" }}>
            <Grid item xs={8}>
              <TextField
                required={true}
                label={t("message.dialog.subject")}
                variant="outlined"
                name="subject"
                value={subject}
                onChange={handleTextChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl required={true} variant="outlined" fullWidth>
                <InputLabel>{t("message.dialog.voice")}</InputLabel>
                <Select label={t("message.dialog.voice")}
                  onChange={handleVoiceChange}
                  value={voice}
                >
                  <MenuItem value={undefined}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl required={true} variant="outlined" fullWidth>
                <InputLabel>{t("message.dialog.language")}</InputLabel>
                <Select
                  label={language}
                  onChange={handleLanguageChange}
                  value={language}
                >
                  <MenuItem value={undefined}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"de_DE"}>Deutsch</MenuItem>
                  <MenuItem value={"en_EN"}>English</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <TextField
            required={true}
            name={"body"}
            label={`${t("message.dialog.body")} (${body.length}/140) (${Math.floor(body.length / 140) + 1
              })`}
            multiline
            rows={10}
            rowsMax={25}
            variant="outlined"
            value={body}
            onChange={handleTextChange}
          />
        </form>
      </Dialog>
      {sendDialogOpen && (
        <Dialog
          open={true}
          onClose={() => {
            setSendDialogOpen(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">{t("message.dialog.send")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("message.dialog.confirmation", { amount: Math.floor(body.length / 140) + 1, recipients: recipients.length })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSendDialogOpen(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button onClick={() => sendMessage()} color="primary" autoFocus>
              {t("send")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
