import {
  Button,
  ButtonGroup,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "../../services/contacts/Contact";
import { Group } from "../../services/groups/Group";
import { groupStore } from "../../services/groups/GroupStore";

const useStyles = makeStyles(
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    buttons: {
      margin: "1.5rem 0.5rem",
    },
    list: {
      flex: 1,
      margin: "0.5rem",
    },
  })
);

function renderContactsCell(contacts: Contact[]): ReactNode[] {
  let components: ReactNode[] = [];
  contacts.sort().forEach((contact, index) => {
    if (index < 5) {
      components.push(renderContact(contact, index));
    }
  });

  if (contacts.length > 5) {
    const rest = contacts.length - 5;
    components.push(renderRest(rest));
  }
  return components;
}

function renderContact(contact: Contact, index: number) {
  return (
    <Chip
      key={`${index}${contact}`}
      style={{ marginLeft: "0.5rem" }}
      size="small"
      icon={
        <Icon style={{ marginLeft: "0.4rem" }} fontSize="small">
          account_circle
        </Icon>
      }
      label={getInitials(`${contact.last_name} ${contact.first_name}`)}
      title={`${contact.last_name}, ${contact.first_name}`}
      variant="outlined"
    />
  );
}

function renderRest(amount: number) {
  return (
    <Chip
      key={`rest${amount}`}
      style={{ marginLeft: "0.5rem" }}
      size="small"
      label={`+ ${amount}`}
      variant="outlined"
    />
  );
}

function getInitials(string: string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}


export default function GroupList(props: {
  groups: Group[];
  onOpenGroupDialog: (groupToEdit?: Group) => void;
  openMessageDialog: (groups: Group[]) => void;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [selectedIds, setSelectedIds] = useState([] as GridRowId[]);

  const columns: GridColDef[] = [
    { field: "name", headerName: t("groups.list.name"), flex: 1 },
    {
      field: "members",
      headerName: t("groups.list.contacts"),
      sortable: false,
      flex: 5,
      renderCell: (params: GridCellParams) => {
        return <div>{renderContactsCell(params.value as Contact[])}</div>;
      },
    },
  ];

  const columnsWithDisabledSend: GridColDef[] = [
    ...columns,
    {
      headerName: t("groups.list.create_message"),
      field: "id",
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title="Create new message">
            <IconButton disabled aria-label="Create new message">
              <Icon>send</Icon>
            </IconButton>
          </Tooltip>
        );
      },
      flex: 1,
      align: "center",
      hideSortIcons: true,
      editable: false,
      disableColumnMenu: true,
    },
  ];

  const columnsWithSend: GridColDef[] = [
    ...columns,
    {
      headerName: t("groups.list.create_message"),
      field: "id",
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title="Create new message">
            <IconButton aria-label="Create new message">
              <Icon>send</Icon>
            </IconButton>
          </Tooltip>
        );
      },
      flex: 1,
      align: "center",
      hideSortIcons: true,
      editable: false,
      disableColumnMenu: true,
    },
  ];

  function handleGroupEdit(groupIdToEdit: number) {
    const groupToEdit = props.groups.find(
      (group) => group.id === groupIdToEdit
    );
    props.onOpenGroupDialog(groupToEdit);
  }

  function handleCellClick(params: GridCellParams) {
    if (params.colDef.field === "id") {
      let group = props.groups.find((group) => {
        return group.id === params.id;
      });

      if (group !== undefined) {
        props.openMessageDialog([group]);
      }
    }
  }

  function handleDeleteGroup() {
    setDeleteDialogOpen(false);
    groupStore
      .deleteGroup(selectedIds.map((ids) => +ids))
      .then(() => {
        enqueueSnackbar(
          t("groups.list.delete_message", { amount: selectedIds.length }),
          { variant: "success" }
        );
      })
      .catch((ids) => {
        enqueueSnackbar(t("groups.list.delete_message_error", { amount: selectedIds.length }),
          { variant: "error", });
      });
  }

  function handleSendSelected() {
    let selectedGroups: Group[] = [];
    selectedIds.forEach((selectedId) =>
      selectedGroups.push(
        props.groups.find((group) => group.id === selectedId)!
      )
    );

    props.openMessageDialog(selectedGroups);
  }

  return (
    <div className={classes.container}>
      <div className={classes.buttons}>
        <ButtonGroup>
          <Button
            onClick={() => props.onOpenGroupDialog()}
            endIcon={<Icon>add</Icon>}
          >
            {t("new")}
          </Button>
          <Button
            // workaround for bug on Safari / iOS https://github.com/mui-org/material-ui/issues/26251
            key={`edit${selectedIds.length !== 1}`}
            onClick={() => handleGroupEdit(selectedIds[0] as number)}
            disabled={selectedIds.length !== 1}
            endIcon={<Icon>edit</Icon>}
          >
            {t("edit")}
          </Button>
          <Button
            // workaround for bug on Safari / iOS https://github.com/mui-org/material-ui/issues/26251
            key={`delete${selectedIds.length === 0}`}
            onClick={() => setDeleteDialogOpen(true)}
            disabled={selectedIds.length === 0}
            color="secondary"
            endIcon={<Icon>delete</Icon>}
          >
            {t("delete")}
          </Button>
          <Button
            // workaround for bug on Safari / iOS https://github.com/mui-org/material-ui/issues/26251
            key={`send${selectedIds.length === 0}`}
            onClick={() => handleSendSelected()}
            disabled={selectedIds.length === 0}
            color="primary"
            endIcon={<Icon>send</Icon>}
          >
            {t("groups.send")}
          </Button>
        </ButtonGroup>
      </div>
      <div className={classes.list}>
        <DataGrid
          rows={props.groups}
          columns={
            selectedIds.length > 1 ? columnsWithDisabledSend : columnsWithSend
          }
          checkboxSelection
          onSelectionModelChange={(newSelection: GridSelectionModel) => {
            setSelectedIds(newSelection);
          }}
          selectionModel={selectedIds}
          onCellClick={handleCellClick}
        />
      </div>
      {deleteDialogOpen && (
        <Dialog
          open={true}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">{t("groups.dialog.title.delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("groups.list.delete_confirmation", { amount: selectedIds.length })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteDialogOpen(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => handleDeleteGroup()}
              color="secondary"
              autoFocus
            >
              {t("delete")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
