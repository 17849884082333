import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Icon,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { userStore } from "../../services/user/UserStore";
import { User } from "../../services/user/User";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      display: "flex",
      flexDirection: "column",
      "& .MuiFormControl-root": {
        margin: theme.spacing(2),
        flex: 1,
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
    multiselect: {
      margin: "0 1rem",
    },
  })
);

/* 
  This gives a warning with React Strictmode, should be fixed in version 5.0.
  Which hopefully realeases soon.
  https://github.com/mui-org/material-ui/milestones
*/
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterComponent(props: { close: () => void }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [state, setState] = React.useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
  });

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  async function handleRegisterUser() {
    let newUser: User = {
      id: Math.floor(Math.random() * 100),
      username: name,
    };
    await userStore.registerNewUser(newUser);
    props.close();
  }

  const { name, emailAddress, phoneNumber } = state;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={() => props.close()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={() => props.close()}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("register.title")}
            </Typography>
            <Button
              form="contact-form"
              type="submit"
              color="inherit"
              endIcon={<Icon>save</Icon>}
            >
              {t("register.save")}
            </Button>
          </Toolbar>
        </AppBar>
        <form
          id="contact-form"
          className={classes.root}
          onSubmit={() => handleRegisterUser()}
        >
          <TextField
            required={true}
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="name"
            value={name}
            onChange={handleTextChange}
          />
          <TextField
            required={true}
            id="outlined-basic"
            label="Email address"
            variant="outlined"
            name="emailAddress"
            value={emailAddress}
            onChange={handleTextChange}
          />
          <TextField
            required={true}
            id="outlined-basic"
            label="Phone number"
            variant="outlined"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handleTextChange}
          />
        </form>
      </Dialog>
    </div>
  );
}
