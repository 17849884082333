import {
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      background: "#2e7ca0",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "2rem",
    },
    title: {
      margin: "1rem 1rem 3rem 1rem",
    },
  })
);

export default function LoadingScreen() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h2" noWrap className={classes.title}>
          Loading Fonsie
        </Typography>
        <CircularProgress />
      </Paper>
    </div>
  );
}
