import { BehaviorSubject } from "rxjs";
import { groupStore } from "../groups/GroupStore";
import { Contact } from "./Contact";
import ContactService from "./ContactService";

export class ContactStore {
  private readonly _contacts = new BehaviorSubject<Contact[]>([]);

  readonly contacts$ = this._contacts.asObservable();

  private get contacts(): Contact[] {
    return this._contacts.getValue();
  }

  private set contacts(contacts: Contact[]) {
    this._contacts.next(contacts);
  }

  fetchContacts(): Promise<void> {
    return ContactService.fetchAllContacts().then((contacts) => {
      this.contacts = contacts;
    });
  }

  getContactById(contactId: number) {
    return this.contacts.find((contact) => contact.id === contactId);
  }

  async saveContact(newContact: Contact): Promise<any> {
    let index = this.contacts.findIndex(
      (contact) => contact.id === newContact.id
    );
    if (index > -1) {
      return ContactService.saveContact(newContact, true).then(
        (data: Contact) => {
          let newContacts = this.contacts;
          newContacts[index] = data;
          this.contacts = [...newContacts];
          groupStore.fetchGroups();
        }
      );
    } else {
      return ContactService.saveContact(newContact).then((data: Contact) => {
        this.contacts = [...this.contacts, data];
        groupStore.fetchGroups();
      });
    }
  }

  async deleteContact(idsToDelete: number[]): Promise<number[] | null> {
    let unsuccessfulDelete: number[] = [];
    return new Promise((resolve, reject) => {
      idsToDelete.forEach(async (idToDelete) => {
        await ContactService.deleteContact(idToDelete)
          .then(() => {
            let newContacts = this.contacts.filter(
              (contact) => !idsToDelete.includes(contact.id!)
            );
            this.contacts = [...newContacts];
          })
          .catch(() => {
            unsuccessfulDelete.push(idToDelete);
          });
      });
      if (unsuccessfulDelete.length === 0) {
        resolve(null);
      } else {
        reject(unsuccessfulDelete);
      }
    });
  }
}

export const contactStore = new ContactStore();
