import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import useContacts from "../../services/contacts/ContactHook";
import { Contact } from "../../services/contacts/Contact";
import { InputBase, InputAdornment, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
      width: "100%",
      marginBottom: "1rem",
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 300,
      height: 400,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    input: {
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: "5px",
      width: "100%",
      margin: "1rem 8rem",
      padding: "0.5rem",
      flex: 1,
    },
    transfer: {
      border: "1px solid rgba(0,0,0,0.1)",
      display: "flex",
      flexDirection: "column",
      width: "80%",
      backgroundColor: "rgba(0,0,0,0.01)",
      borderRadius: "5px",
    },
    transferList: {
      display: "flex",
      flexDirection: "row",
      padding: "1rem",
      justifyContent: "space-around",
      alignItems: "center",
    },
  })
);

function not(a: Contact[], b: Contact[]) {
  return a.filter((value) => b.map((bc) => bc.id).indexOf(value.id) === -1);
}

function intersection(a: Contact[], b: Contact[]) {
  return a.filter((value) => b.map((bc) => bc.id).indexOf(value.id) !== -1);
}

function union(a: Contact[], b: Contact[]) {
  return [...a, ...not(b, a)];
}

export default function ContactsTransferList(props: {
  onChange: (selectedContacts: Contact[]) => void;
  initialSelectedContacts?: Contact[];
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { contacts } = useContacts();
  const [checked, setChecked] = useState<Contact[]>([]);
  const [left, setLeft] = useState<Contact[]>(contacts);
  const [right, setRight] = useState<Contact[]>(
    props.initialSelectedContacts || []
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([] as Contact[]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    if (contacts) {
      const leftContacts = props.initialSelectedContacts
        ? not(contacts, props.initialSelectedContacts)
        : contacts;
      setLeft(leftContacts);
    }
  }, [contacts, props.initialSelectedContacts]);

  const handleToggle = (value: Contact) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: Contact[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: Contact[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    props.onChange(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    props.onChange(not(right, rightChecked));
  };

  const byLastThenFirstName = (a: Contact, b: Contact) => {
    if (a.last_name < b.last_name) {
      return -1;
    }
    if (a.last_name > b.last_name) {
      return 1;
    }
    if (a.first_name < b.first_name) {
      return -1;
    }
    if (a.first_name > b.first_name) {
      return 1;
    }
    return 0;
  };

  const customList = (title: React.ReactNode, items: Contact[]) => {
    const sortedItems = items.slice().sort(byLastThenFirstName);
    return (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(sortedItems)}
              checked={
                numberOfChecked(sortedItems) === sortedItems.length &&
                sortedItems.length !== 0
              }
              indeterminate={
                numberOfChecked(sortedItems) !== sortedItems.length &&
                numberOfChecked(sortedItems) !== 0
              }
              disabled={sortedItems.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(sortedItems)}/${items.length} ${t("transferlist.selected")}`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {sortedItems.map((value: Contact) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem
                key={value.id!}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${value.last_name}, ${value.first_name}`}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  function handleSearchQueryUpdate(event: {
    target: { value: React.SetStateAction<string> };
  }) {
    let newQuery: string = event.target.value.toString();
    setSearchQuery(newQuery);
    if (newQuery.length > 2) {
      let newFilteredContacts = contacts.filter((contact: Contact) => {
        return (
          `${contact.last_name} ${contact.first_name}`
            .toLowerCase()
            .includes(newQuery.toLowerCase()) ||
          contact.email?.toLowerCase().includes(newQuery.toLowerCase())
        );
      });
      setFilteredContacts(newFilteredContacts);
    } else {
      setFilteredContacts([]);
    }
  }

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <div className={classes.transfer}>
        <div>
          <Grid container>
            <InputBase
              className={classes.input}
              placeholder={t("transferlist.search")}
              inputProps={{ "aria-label": "search contacts" }}
              value={searchQuery}
              onChange={handleSearchQueryUpdate}
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              }
              endAdornment={
                searchQuery.length > 2 &&
                filteredContacts.length === 0 && (
                  <InputAdornment position="end" title="No results">
                    <Icon>error-outline</Icon>
                  </InputAdornment>
                )
              }
              style={{
                color:
                  searchQuery.length > 2 && filteredContacts.length === 0
                    ? "red"
                    : "black",
              }}
            />
          </Grid>
        </div>
        <div className={classes.transferList}>
          <Grid item>
            {customList(
              t("transferlist.left"),
              filteredContacts.length > 0
                ? intersection(left, filteredContacts)
                : left
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            {customList(
              t("transferlist.right"),
              filteredContacts.length > 0
                ? intersection(right, filteredContacts)
                : right
            )}
          </Grid>
        </div>
      </div>
    </Grid>
  );
}
