import axios from "axios";

export class Api {
  public api: any;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL + "/api/v1/",
      withCredentials: true,
    });

    //request interceptor to add the auth token header to requests
    this.api.interceptors.request.use(
      (config: { headers: { Authorization: string } }) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error: any) => {
        Promise.reject(error);
      }
    );

    //response interceptor to refresh token on receiving token expired error
    this.api.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async function (error: { config: any; response: { status: number } }) {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          return api
            .post("auth/token/refresh")
            .then((res: { status: number; data: string }) => {
              if (res.status === 200) {
                localStorage.setItem("accessToken", res.data);
                return api(originalRequest);
              }
            })
            .catch((error: any) => {
              if (error.response.status === 401) {
                window.location.href = "/login?sessionExpired=true";
              } else {
                return api(originalRequest);
              }
            });
        }
        if (originalRequest._retry) {
          localStorage.clear();
          window.location.href = "/login?sessionExpired=true";
        }
        return Promise.reject(error);
      }
    );
  }
}

export const api = new Api().api;

export const apiNoRetry = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api/v1/",
  withCredentials: true,
});