import { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Chip, Grid, Icon, TextField } from "@material-ui/core";
import { Contact } from "../../services/contacts/Contact";
import { Recipient } from "../../services/messages/Message";
import { useTranslation } from "react-i18next";
import useContacts from "../../services/contacts/ContactHook";
import { Autocomplete } from "@material-ui/lab";
import { ContactPreference } from "./ContactPreference";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contacts: {
      padding: "1rem",
      border: "1px solid rgba(0,0,0,0.3)",
      borderRadius: "5px",
      overflowY: "scroll",
      maxHeight: "15rem",
      minHeight: "10rem"
    },
    chip: {
      margin: "0.1rem",
    },
    searchGrid: {
      maxWidth: "97%"
    },
    noRecipients: {
      display: "flex",
      justifyContent: "center",
      verticalAlign: "middle",
      color: "rgba(0,0,0,0.3)"
    }
  })
);

export default function MessageContactSelecter(props: {
  contacts: Contact[];
  onUpdate: (recipients: Recipient[]) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { contacts } = useContacts();
  const [openContactList, setOpenContactList] = useState(false);
  const [currentContacts, setCurrentContacts] = useState(props.contacts || [] as Contact[]);
  const [state, setState] = useState({
    mailContacts:
      props.contacts &&
      props.contacts.filter((contact) => {
        return contact.preference.includes(ContactPreference.EMAIL);
      }),
    phoneContacts:
      props.contacts &&
      props.contacts.filter((contact) => {
        return contact.preference.includes(ContactPreference.CALL);
      }),
    textContacts:
      props.contacts &&
      props.contacts.filter((contact) => {
        return contact.preference.includes(ContactPreference.SMS);
      }),
    recipients: [],
  });

  const { mailContacts, phoneContacts, textContacts } = state;

  const handleUpdate = (newContacts: Contact[]) => {
    setCurrentContacts(newContacts);
    prepareRecipients(newContacts);
  }

  const prepareRecipients = (contacts: Contact[]) => {
    const newMailContacts = contacts.filter((contact) => {
      return contact.preference.includes(ContactPreference.EMAIL);
    });
    const newPhoneContacts = contacts.filter((contact) => {
      return contact.preference.includes(ContactPreference.CALL);
    });
    const newTextContacts = contacts.filter((contact) => {
      return contact.preference.includes(ContactPreference.SMS);
    });

    updateRecipients(
      newMailContacts,
      newPhoneContacts,
      newTextContacts)
    setState({
      ...state,
      mailContacts: newMailContacts,
      phoneContacts: newPhoneContacts,
      textContacts: newTextContacts
    })
  }

  const handleMailDelete = (contactToDelete: Contact) => {
    const newRecipients = mailContacts.filter((contact) => {
      return contact.id !== contactToDelete.id;
    });
    setState({ ...state, mailContacts: newRecipients });
    updateRecipients(newRecipients, phoneContacts, textContacts);
  };

  const handlePhoneDelete = (contactToDelete: Contact) => {
    const newRecipients = phoneContacts.filter((contact) => {
      return contact.id !== contactToDelete.id;
    });
    setState({ ...state, phoneContacts: newRecipients });
    updateRecipients(mailContacts, newRecipients, textContacts);
  };

  const handleTextDelete = (contactToDelete: Contact) => {
    const newRecipients = textContacts.filter((contact) => {
      return contact.id !== contactToDelete.id;
    });
    setState({ ...state, textContacts: newRecipients });
    updateRecipients(mailContacts, phoneContacts, newRecipients);
  };

  function updateRecipients(
    mail: Contact[],
    phone: Contact[],
    text: Contact[]
  ) {
    let recipients: Recipient[] = [];

    mail.forEach((contact) => {
      recipients.push({
        contact_id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        method: ContactPreference.EMAIL,
      } as Recipient);
    });
    phone.forEach((contact) => {
      recipients.push({
        contact_id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        method: ContactPreference.CALL,
      } as Recipient);
    });
    text.forEach((contact) => {
      recipients.push({
        contact_id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        method: ContactPreference.SMS,
      } as Recipient);
    });
    props.onUpdate(recipients);
  }

  return (
    <div className={classes.contacts}>
      <Grid className={classes.searchGrid}>
        <Autocomplete
          multiple
          options={contacts}
          value={currentContacts}
          getOptionLabel={option => `${option.last_name}, ${option.first_name}`}
          renderInput={params => (
            <TextField
              {...params}
              label={t("message.dialog.to")}
              variant="outlined"
            />
          )}
          open={openContactList}
          onChange={(_, changedContacts) => {
            handleUpdate(changedContacts);
          }}
          onInputChange={(_, changedValue) => {
            if (changedValue.length > 0) {
              setOpenContactList(true);
            } else {
              setOpenContactList(false);
            }
          }} />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{`${t("message.dialog.mail")}: `}</Typography>
          {mailContacts.length === 0 && <div className={classes.noRecipients}>{t("message.dialog.empty")}</div>}
          {mailContacts.map((contact, index) => {
            return (
              <Chip
                className={classes.chip}
                key={`${index}mail${contact.last_name}`}
                size="small"
                icon={
                  <Icon style={{ marginLeft: "0.4rem" }} fontSize="small">
                    mail
                  </Icon>
                }
                label={`${contact.first_name} ${contact.last_name}`}
                onDelete={() => handleMailDelete(contact)}
              />
            );
          })}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{`${t("message.dialog.phone")}: `}</Typography>
          {phoneContacts.length === 0 && <div className={classes.noRecipients}>{t("message.dialog.empty")}</div>}
          {phoneContacts.map((contact, index) => {
            return (
              <Chip
                className={classes.chip}
                key={`${index}phone${contact.last_name}`}
                size="small"
                icon={
                  <Icon style={{ marginLeft: "0.4rem" }} fontSize="small">
                    phone
                  </Icon>
                }
                label={`${contact.first_name} ${contact.last_name}`}
                onDelete={() => handlePhoneDelete(contact)}
              />
            );
          })}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{`${t("message.dialog.text")}: `}</Typography>
          {textContacts.length === 0 && <div className={classes.noRecipients}>{t("message.dialog.empty")}</div>}
          {textContacts.map((contact, index) => {
            return (
              <Chip
                className={classes.chip}
                key={`${index}text${contact.last_name}`}
                size="small"
                icon={
                  <Icon style={{ marginLeft: "0.4rem" }} fontSize="small">
                    textsms
                  </Icon>
                }
                label={`${contact.first_name} ${contact.last_name}`}
                onDelete={() => handleTextDelete(contact)}
              />
            );
          })}
        </Grid>
      </Grid>
    </div >
  );
}
