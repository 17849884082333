import {
  Button,
  CircularProgress,
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { userStore } from "../../services/user/UserStore";
import RegisterDialog from "../user/RegisterDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "absolute",
      background: "#2E7CA0",
      left: 0,
      top: 0,
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "2rem",
    },
    title: {
      margin: "1rem",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    textfield: {
      padding: "0.5rem",
      margin: "1rem",
    },
    loginButton: {
      margin: "1rem",
    },
    loading: {
      margin: "1rem",
    },
  })
);

export default function Login(props: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const login = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);
    await userStore
      .loginUser(username, password)
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(t("snackbar.incorrect_credentials"), {
            variant: "error",
          });
        } else if (error.request) {
          enqueueSnackbar(t("snackbar.error"), {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleRegisterNewUser() {
    setRegisterDialogOpen(true);
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    let sessionExpired = search.get("sessionExpired");
    if (sessionExpired) {
      enqueueSnackbar(t("snackbar.session_expired"), {
        variant: "info",
        preventDuplicate: true,
      });
      search.delete("sessionExpired");
      history.replace({
        search: "",
      });
    }
  });

  return (
    <div className={classes.container}>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h4" noWrap className={classes.title}>
          {t("login.title")}
        </Typography>
        <form className={classes.form} onSubmit={login}>
          <TextField
            id="standard-required"
            label={t("login.username")}
            className={classes.textfield}
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            disabled={loading}
          />
          <TextField
            id="standard-password-input"
            label={t("login.password")}
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            className={classes.textfield}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!loading && (
            <Button className={classes.loginButton} type="submit">
              {t("login.login")}
            </Button>
          )}
        </form>
        {!loading && (
          <Button
            className={classes.loginButton}
            onClick={() => handleRegisterNewUser()}
          >
            {t("login.register")}
          </Button>
        )}

        {loading && <CircularProgress className={classes.loading} />}
      </Paper>
      {registerDialogOpen && (
        <RegisterDialog
          close={() => {
            setRegisterDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}
