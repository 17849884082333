import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { Icon, TextField } from "@material-ui/core";
import { Contact } from "../../services/contacts/Contact";
import { Group } from "../../services/groups/Group";
import { groupStore } from "../../services/groups/GroupStore";
import ContactsTransferList from "../contacts/ContactsTransferList";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { contactStore } from "../../services/contacts/ContactStore";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      display: "flex",
      flexDirection: "column",
      "& .MuiFormControl-root": {
        margin: theme.spacing(2),
        flex: 1,
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

/* 
  This gives a warning with React Strictmode, should be fixed in version 5.0.
  Which hopefully realeases soon.
  https://github.com/mui-org/material-ui/milestones
*/
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GroupDialog(props: {
  close: () => void;
  group?: Group;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [groupName, setGroupName] = useState(props.group?.name || "");
  const [groupContacts, setGroupContacts] = useState(initializeMembers());

  const handleSaveGroup = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let newGroup: Group = {
      id: props.group?.id || undefined,
      name: groupName,
      members: groupContacts,
    };
    groupStore
      .saveGroup(newGroup)
      .then(() => {
        enqueueSnackbar(t("groups.dialog.save_message", { name: newGroup.name }), {
          variant: "success",
        });
        props.close();
      })
      .catch((error) => {
        enqueueSnackbar(`${t("snackbar.error_short")} \n ${error}`, {
          variant: "error",
        });
      });
  };

  function initializeMembers(): Contact[] {
    if (props.group && props.group.members) {
      let members: Contact[] = [];
      props.group.members.forEach((contact) => {
        let foundContact = contactStore.getContactById(contact.id!);
        foundContact && members.push(foundContact);
      });
      return members;
    } else {
      return [];
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={() => props.close()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={() => props.close()}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.group ? t("groups.dialog.title.update", { name: `${props.group.name}` })
                : t("groups.dialog.title.create")}
            </Typography>
            <Button
              form="contact-form"
              type="submit"
              color="inherit"
              endIcon={<Icon>save</Icon>}
            >
              {t("save")}
            </Button>
          </Toolbar>
        </AppBar>
        <form
          id="contact-form"
          className={classes.root}
          onSubmit={handleSaveGroup}
        >
          <TextField
            required={true}
            id="outlined-basic"
            label={t("groups.dialog.name")}
            variant="outlined"
            name="name"
            value={groupName}
            onChange={(event) => setGroupName(event.target.value)}
          />
          <ContactsTransferList
            onChange={(contacts) => setGroupContacts(contacts)}
            initialSelectedContacts={props.group?.members}
          />
        </form>
      </Dialog>
    </div>
  );
}
