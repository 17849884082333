import { AxiosResponse } from "axios";
import { api, apiNoRetry } from "../api/Axios";
import { LoginRequest, User } from "./User";

export default class UserService {
  static async fetchAllUsers(): Promise<User[]> {
    // const res = await fetch("URL");
    // const data = await res.json();
    // return data;
    return new Promise((resolve) => {
      resolve([
        { username: "Eelko" },
        { username: "Tim" },
        { username: "Marijn" },
      ]);
    });
  }

  static async loginUser(loginRequest: LoginRequest): Promise<AxiosResponse> {
    return apiNoRetry.post("auth/login", loginRequest);
  }

  static async logout(): Promise<AxiosResponse> {
    return api.post("auth/logout");
  }
}
