import { Group, GroupRequest } from "./Group";
import { api } from "../api/Axios";

export default class GroupService {
  static async fetchAllGroups(): Promise<Group[]> {
    return (await api.get("/accounts/me/groups")).data;
  }

  static async saveGroup(
    group: GroupRequest,
    update: boolean = false
  ): Promise<Group> {
    if (update) {
      return (await api.put(`/accounts/me/groups/${group.id}`, group)).data;
    } else {
      return (await api.post("/accounts/me/groups", group)).data;
    }
  }

  static async deleteGroup(groupId: number): Promise<void> {
    return await api.delete(`/accounts/me/groups/${groupId}`);
  }
}
