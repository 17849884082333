import { api } from "../api/Axios";
import { Contact } from "./Contact";

export default class ContactService {
  static async fetchAllContacts(): Promise<Contact[]> {
    return (await api.get("/accounts/me/contacts")).data;
  }

  static async saveContact(
    contact: Contact,
    update: boolean = false
  ): Promise<Contact> {
    if (update) {
      return (await api.put(`/accounts/me/contacts/${contact.id}`, contact))
        .data;
    } else {
      return (await api.post(`/accounts/me/contacts`, contact)).data;
    }
  }

  static async deleteContact(contactId: number): Promise<void> {
    return await api.delete(`/accounts/me/contacts/${contactId}`);
  }
}
