import { useEffect } from "react";
import { useState } from "react";
import { User } from "./User";
import { userStore } from "./UserStore";

function useAuth(): User | undefined {
  const [loggedInUser, setLoggedInUser] = useState<User>();

  useEffect(() => {
    const loginSubscription = userStore.loggedInUser$.subscribe(
      (loggedInUser) => {
        setLoggedInUser(loggedInUser);
      }
    );
    return () => {
      loginSubscription.unsubscribe();
    };
  }, [loggedInUser]);

  return loggedInUser;
}

export default useAuth;
