import {
  Chip,
  Button,
  ButtonGroup,
  Icon,
  createStyles,
  makeStyles,
  Theme,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  InputAdornment,
  InputBase,
} from "@material-ui/core";
import {
  GridColDef,
  DataGrid,
  GridCellParams,
  GridRowId,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Contact } from "../../services/contacts/Contact";

import useContacts from "../../services/contacts/ContactHook";
import { contactStore } from "../../services/contacts/ContactStore";
import { ContactPreference } from "../message/ContactPreference";
import ContactDialog from "./ContactDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "80%",
    },
    table: {
      flex: 1,
      margin: "0.5rem"
    },
    input: {
      marginLeft: theme.spacing(1),
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: "2px",
      width: "100%",
      margin: "0.5rem",
      padding: "0.5rem",
    },
  })
);

function groupSort(a: { name: string }, b: { name: string }) {
  if (a.name === b.name) {
    return 0;
  } else {
    return +(a.name > b.name)
  }
}

function renderMethod(method: string) {
  let chipIcon: string;
  switch (method) {
    case ContactPreference.EMAIL:
      chipIcon = "mail";
      break;
    case ContactPreference.CALL:
      chipIcon = "phone";
      break;
    case ContactPreference.SMS:
      chipIcon = "textsms";
      break;
    default:
      chipIcon = "";
  }
  return (
    <Chip
      key={method}
      style={{ marginLeft: "0.5rem" }}
      size="small"
      icon={
        <Icon style={{ marginLeft: "0.4rem" }} fontSize="small">
          {chipIcon}
        </Icon>
      }
      label={<Trans i18nKey={`contacts.chip.${method}`}>
      </Trans>}
      variant="outlined"
    />
  );
}

function renderGroupCell(params: GridCellParams) {
  let components: ReactNode[] = [];
  let groupNames: string[] = [];
  (params.value as [{ name: string }]).sort(groupSort).forEach((group, index) => {
    if (index < 1) {
      components.push(renderGroup(group.name));
    }
    groupNames.push(group.name);
  });
  if (groupNames.length > 1) {
    const rest = groupNames.length - 1;
    components.push(renderRest(rest));
  }
  return <div title={groupNames.join(', \r\n')}>{components}</div>;
}

function renderGroup(name: string) {
  return (
    <Chip
      key={name}
      style={{ marginLeft: "0.5rem", overflow: "hidden", textOverflow: "ellipses", maxWidth: "10rem" }}
      size="small"
      label={name}
      variant="outlined"
    />);
}

function renderRest(amount: number) {
  return (
    <Chip
      key={`rest${amount}`}
      style={{ marginLeft: "0.5rem" }}
      size="small"
      label={`+ ${amount}`}
      variant="outlined"
    />
  );
}

export default function Contacts() {
  const classes = useStyles();
  const { contacts } = useContacts();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState({} as Contact);
  const [selectedIds, setSelectedIds] = useState([] as GridRowId[]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([] as Contact[]);

  // Not pretty but need the translation
  const columns: GridColDef[] = [
    {
      field: "last_name",
      headerName: t("contacts.lastname"),
      flex: 1,
    },
    {
      field: "first_name",
      headerName: t("contacts.firstname"),
      flex: 1,
    },
    {
      field: "preference",
      headerName: t("contacts.methods"),
      sortable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        let components: ReactNode[] = [];
        (params.value as string[]).sort().forEach((method) => {
          components.push(renderMethod(method));
        });
        return <div>{components}</div>;
      },
    },
    {
      field: "email",
      headerName: t("contacts.mail"),
      flex: 1,
    },
    {
      field: "telephone",
      headerName: t("contacts.phone"),
      flex: 1,
    },
    {
      field: "groups",
      headerName: t("groups.title"),
      sortable: false,
      flex: 1,
      renderCell: renderGroupCell
    },
  ];

  function handleEditDialogOpen() {
    if (selectedIds.length === 1) {
      let contactToEdit = contacts.find(
        (contact) => contact.id === selectedIds[0]
      );
      if (contactToEdit) {
        setSelectedContact(contactToEdit);
      }
    }
    setContactDialogOpen(true);
  }

  function handleDialogClose() {
    setSelectedContact({} as Contact);
    setContactDialogOpen(false);
  }

  function handleDeleteContact() {
    setDeleteDialogOpen(false);
    contactStore
      .deleteContact(selectedIds.map((ids) => +ids))
      .then(() => {
        enqueueSnackbar(
          t("contact.dialog.delete_message", { amount: selectedIds.length }),
          { variant: "success" }
        );
      })
      .catch((ids) => {
        enqueueSnackbar(t("contact.dialog.delete_message_error", { amount: ids.length }), {
          variant: "error",
        });
      });
  }

  function handleSearchQueryUpdate(event: {
    target: { value: React.SetStateAction<string> };
  }) {
    let newQuery: string = event.target.value.toString();
    setSearchQuery(newQuery);
    if (newQuery.length > 2) {
      let newFilteredContacts = contacts.filter((contact: Contact) => {
        return (
          `${contact.first_name} ${contact.last_name}`
            .toLowerCase()
            .includes(newQuery.toLowerCase()) ||
          contact.email?.toLowerCase().includes(newQuery.toLowerCase())
        );
      });
      setFilteredContacts(newFilteredContacts);
    } else {
      setFilteredContacts([]);
    }
  }

  return (
    <div className={classes.container}>
      <h1>{t("contacts.title")}</h1>
      <Grid container>
        <InputBase
          className={classes.input}
          placeholder={t("contacts.search")}
          inputProps={{ "aria-label": "search contacts" }}
          value={searchQuery}
          onChange={handleSearchQueryUpdate}
          type="search"
          startAdornment={
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>
          }
          endAdornment={
            searchQuery.length > 2 &&
            filteredContacts.length === 0 && (
              <InputAdornment position="end" title="No results">
                <Icon>error-outline</Icon>
              </InputAdornment>
            )
          }
          style={{
            color:
              searchQuery.length > 2 && filteredContacts.length === 0
                ? "red"
                : "black",
          }}
        />
      </Grid>
      <div style={{ margin: "1.5rem 0.5rem" }}>
        <ButtonGroup>
          <Button
            onClick={() => setContactDialogOpen(true)}
            endIcon={<Icon>add</Icon>}
          >
            {t("new")}
          </Button>
          <Button
            // workaround for bug on Safari / iOS https://github.com/mui-org/material-ui/issues/26251
            key={`edit${selectedIds.length !== 1}`}
            onClick={() => handleEditDialogOpen()}
            disabled={selectedIds.length !== 1}
            endIcon={<Icon>edit</Icon>}
          >
            {t("edit")}
          </Button>
          <Button
            // workaround for bug on Safari / iOS https://github.com/mui-org/material-ui/issues/26251
            key={`delete${selectedIds.length === 0}`}
            onClick={() => setDeleteDialogOpen(true)}
            disabled={selectedIds.length === 0}
            color="secondary"
            endIcon={<Icon>delete</Icon>}
          >
            {t("delete")}
          </Button>
        </ButtonGroup>
      </div>
      <div className={classes.table}>
        <DataGrid
          rows={filteredContacts.length === 0 ? contacts : filteredContacts}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={(newSelection: GridSelectionModel) => {
            setSelectedIds(newSelection);
          }}
          selectionModel={selectedIds}
        />
      </div>

      {deleteDialogOpen && (
        <Dialog
          open={true}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">{t("contacts.dialog.title.delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("contacts.dialog.delete_confirmation", { amount: selectedIds.length })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteDialogOpen(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => handleDeleteContact()}
              color="secondary"
              autoFocus
            >
              {t("delete")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {contactDialogOpen && (
        <ContactDialog
          close={() => handleDialogClose()}
          contact={selectedContact}
        />
      )}
    </div>
  );
}
