import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { ExitToApp } from "@material-ui/icons";
import Groups from "./groups/GroupsComponent";
import Contacts from "./contacts/ContactsComponent";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HistoryComponent from "./history/HistoryComponent";
import { userStore } from "../services/user/UserStore";
import { useSnackbar } from "notistack";
import useAuth from "../services/user/AuthHook";
import LanguageSwitch from "./language/LanguageSwitchComponent";
import { Message } from "../services/messages/Message";
import MessageDialog from "./message/MessageDialog";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      display: "flex",
      height: "100%",
      width: "100%",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
    },
    actions: {
      display: "flex",
      flexDirection: "row",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    drawerNavigation: {
      flexGrow: 1,
    },
    email: {
      "& p": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInUser = useAuth();
  const [messageDiaglogOpen, setMessageDialogOpen] = useState(false);


  function logout() {
    userStore
      .logout()
      .then(() => {
        history.push("/login");
        enqueueSnackbar(t("snackbar.logout"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(
          t("snackbar.logout_error"),
          {
            variant: "info",
          }
        );
      });
  }

  function handleRoute(route: string) {
    history.push(route);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            Fonsie
          </Typography>
          <div className={classes.actions}>
            <LanguageSwitch />
            <Button
              aria-label="Sign out"
              title="Sign out"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={logout}
              color="inherit"
              endIcon={<ExitToApp />}
            >
              {t("dashboard.logout")}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <div className={classes.drawerNavigation}>
            <List>
              <ListItem
                button
                key="New message"
                onClick={() => setMessageDialogOpen(true)}
              >
                <ListItemIcon>
                  <Icon>send</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={t("message.title")}
                />
              </ListItem>
              <ListItem
                button
                key="Contacts"
                onClick={() => handleRoute("contacts")}
              >
                <ListItemIcon>
                  <Icon>contacts</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={t("contacts.title")}
                  secondary={t("contacts.subtitle")}
                />
              </ListItem>
              <ListItem
                button
                key="Groups"
                onClick={() => handleRoute("groups")}
              >
                <ListItemIcon>
                  <Icon>group</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={t("groups.title")}
                  secondary={t("groups.subtitle")}
                />
              </ListItem>
              <ListItem
                button
                key="History"
                onClick={() => handleRoute("history")}
              >
                <ListItemIcon>
                  <Icon>history</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={t("history.title")}
                  secondary={t("history.subtitle")}
                />
              </ListItem>
            </List>
          </div>
          <Divider />
          {loggedInUser && (
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={t("dashboard.user_prefix")}
                  secondary={loggedInUser?.username}
                  className={classes.email}
                  title={loggedInUser?.username}
                />
              </ListItem>
            </List>
          )}
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Redirect to="/contacts" />
        <Switch>
          <Route path="/contacts">
            <Contacts />
          </Route>
          <Route path="/groups">
            <Groups />
          </Route>
          <Route path="/history">
            <HistoryComponent />
          </Route>
        </Switch>
      </main>
      {messageDiaglogOpen && (
        <MessageDialog
          close={() => setMessageDialogOpen(false)}
          send={(message: Message) => setMessageDialogOpen(false)}
        />
      )}
    </div>
  );
}
