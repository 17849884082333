import { useEffect } from "react";
import { useState } from "react";
import { Message } from "./Message";
import { messageStore } from "./MessageStore";

interface MessageState {
  messages: Message[];
}

function useMessages(): MessageState {
  const [messages, setMessages] = useState<MessageState>({ messages: [] });

  useEffect(() => {
    const messageSubscription = messageStore.messages$.subscribe(
      (newMessages) => {
        setMessages({ messages: newMessages });
      }
    );
    return () => {
      messageSubscription.unsubscribe();
    };
  }, []);

  return messages;
}

export default useMessages;
