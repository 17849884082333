import { BehaviorSubject } from "rxjs";
import { Group, GroupRequest } from "./Group";
import GroupService from "./GroupService";

export class GroupStore {
  private readonly _groups = new BehaviorSubject<Group[]>([]);

  readonly groups$ = this._groups.asObservable();

  private get groups(): Group[] {
    return this._groups.getValue();
  }

  private set groups(groups: Group[]) {
    this._groups.next(groups);
  }

  fetchGroups(): Promise<void> {
    return GroupService.fetchAllGroups().then((groups: Group[]) => {
      this.groups = groups;
    });
  }

  getGroupById(groupId: number) {
    return this.groups.find((group) => group.id === groupId);
  }

  async saveGroup(newGroup: Group) {
    let groupRequest = {} as GroupRequest;
    groupRequest.name = newGroup.name;
    groupRequest.members = newGroup.members.map((contact) => contact.id!);
    let index = this.groups.findIndex((group) => group.id === newGroup.id);
    if (index > -1) {
      groupRequest.id = newGroup.id;
      return GroupService.saveGroup(groupRequest, true).then((data: Group) => {
        let newGroups = this.groups;
        newGroups[index] = data;
        this.groups = [...newGroups];
      });
    } else {
      return GroupService.saveGroup(groupRequest).then((data: Group) => {
        this.groups = [...this.groups, data];
      });
    }
  }

  async deleteGroup(idsToDelete: number[]): Promise<number[] | null> {
    let unsuccessfulDelete: number[] = [];
    return new Promise((resolve, reject) => {
      idsToDelete.forEach(async (idToDelete) => {
        await GroupService.deleteGroup(idToDelete)
          .then(() => {
            let newGroups = this.groups.filter(
              (group) => !idsToDelete.includes(group.id!)
            );
            this.groups = [...newGroups];
          })
          .catch(() => {
            unsuccessfulDelete.push(idToDelete);
          });
      });
      if (unsuccessfulDelete.length === 0) {
        resolve(null);
      } else {
        reject(unsuccessfulDelete);
      }
    });
  }
}

export const groupStore = new GroupStore();
