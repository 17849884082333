import { Icon, InputAdornment, InputBase } from "@material-ui/core";
import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import useContacts from "../../services/contacts/ContactHook";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: "0.5rem",
    height: "100%",
    overflow: "scroll",
    overflowX: "hidden",
  },
  contact: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "2px",
    margin: "0.3rem",
    padding: "0.5rem",
    background: "white",
  },
  dragging: {
    background: "rgb(225,225,225)",
  },
  title: {
    textAlign: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
  },
  dragIcon: {
    float: "right",
    color: "rgba(0,0,0,0.5)",
  },
}));

export default function ContactsDraggableList() {
  const { contacts } = useContacts();
  const { t } = useTranslation();
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState("");

  function handleSearchQueryUpdate(event: {
    target: { value: React.SetStateAction<string> };
  }) {
    setSearchQuery(event.target.value);
  }

  return (
    <Card className={classes.list} variant="outlined">
      <h2 className={classes.title}>{t("contacts.title")}</h2>
      <Grid container>
        <InputBase
          className={classes.input}
          placeholder={t("contacts.search")}
          inputProps={{ "aria-label": "search contacts" }}
          value={searchQuery}
          onChange={handleSearchQueryUpdate}
          type="search"
          startAdornment={
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>
          }
        />
      </Grid>
      <Droppable droppableId={"contactList"}>
        {(provided) => (
          <Grid innerRef={provided.innerRef} {...provided.droppableProps}>
            {contacts.map((contact, index) => (
              <Draggable
                key={`contactdraggable${contact.last_name!}${index}`}
                draggableId={contact.id!.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <Typography
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    innerRef={provided.innerRef}
                    key={`contactdraggablename${contact.last_name!}${index}`}
                    className={`${classes.contact} ${snapshot.isDragging && classes.dragging
                      }`}
                    hidden={
                      searchQuery.length >= 2 &&
                      !`${contact.last_name} ${contact.first_name}`
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    }
                  >
                    {`${contact.last_name}, ${contact.first_name}`}
                    <Icon className={classes.dragIcon}>drag_indicator</Icon>
                  </Typography>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </Card>
  );
}
