import { Suspense } from "react";
import "./App.css";
import Dashboard from "./components/DashboardComponent";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./components/login/LoginComponent";
import useAuth from "./services/user/AuthHook";
import LoadingScreen from "./components/loading/LoadingScreenComponent";
import { SnackbarProvider } from "notistack";
import RefreshComponent from "./components/refresh/RefreshComponent";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#2E7CA0",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function App() {
  const loggedInUser = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoadingScreen />}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <div className="App">
            <Router>
              <Switch>
                <Route path="/login">
                  {!loggedInUser?.username ? <Login /> : <Redirect to="/" />}
                </Route>
                <Route path="/">
                  {loggedInUser?.username ? (
                    <>
                      <Dashboard />
                      <RefreshComponent />
                    </>
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Route>
              </Switch>
            </Router>
          </div>
        </SnackbarProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
