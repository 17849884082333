import { BehaviorSubject } from "rxjs";
import { Message, MessageRequest } from "./Message";
import MessageService from "./MessageService";

export class MessageStore {
  private readonly _messages = new BehaviorSubject<Message[]>([]);

  readonly messages$ = this._messages.asObservable();

  private get messages(): Message[] {
    return this._messages.getValue();
  }

  private set messages(Messages: Message[]) {
    this._messages.next(Messages);
  }

  async fetchMessages(): Promise<void> {
    const messages = await MessageService.fetchAllMessages();
    this.messages = messages;
  }

  async fetchMessage(id: number): Promise<Message> {
    return new Promise((resolve, reject) => {
      MessageService.fetchMessage(id)
        .then((updatedMessage: Message) => {
          let index = this.messages.findIndex(
            (message) => message.id === updatedMessage.id
          );
          if (index > -1) {
            let newMessages = this.messages;
            newMessages[index] = updatedMessage;
            this.messages = [...newMessages];
          } else {
            this.messages = [...this.messages, updatedMessage];
          }
          resolve(updatedMessage);
        })
        .catch((error: any) => {
          reject(null);
          // reject(error.response.data.message);
        });
    });
  }

  async sendMessage(newMessage: MessageRequest): Promise<void> {
    return new Promise((resolve, reject) => {
      MessageService.sendMessage(newMessage)
        .then((savedMessage: Message) => {
          let index = this.messages.findIndex(
            (message) => message.id === savedMessage.id
          );
          if (index > -1) {
            let newMessages = this.messages;
            newMessages[index] = savedMessage;
            this.messages = [...newMessages];
          } else {
            this.messages = [...this.messages, savedMessage];
          }
          resolve();
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  }
}

export const messageStore = new MessageStore();
