import { useEffect } from "react";
import { useState } from "react";
import { Contact } from "./Contact";
import { contactStore } from "./ContactStore";

interface ContactState {
  contacts: Contact[];
}

function useContacts(): ContactState {
  const [contacts, setContacts] = useState<ContactState>({ contacts: [] });

  useEffect(() => {
    const contactSubscription = contactStore.contacts$.subscribe(
      (newContacts) => {
        setContacts({ contacts: newContacts });
      }
    );
    return () => {
      contactSubscription.unsubscribe();
    };
  }, []);

  return contacts;
}

export default useContacts;
