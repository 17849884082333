import {
  createStyles,
  Icon,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { GridColDef, DataGrid, GridCellParams } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EmailMessage, Message } from "../../services/messages/Message";
import useMessages from "../../services/messages/MessageHook";
import { messageStore } from "../../services/messages/MessageStore";
import HistoryDetailDialog from "./HistoryDetailDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "80%",
    },
    table: {
      flex: 1,
      margin: "0.5rem"
    },
  })
);

export default function HistoryComponent() {
  const classes = useStyles();
  const { messages } = useMessages();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [messageDiaglogOpen, setMessageDialog] = useState(false);
  const [updatingMessage, setUpdatingMessage] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({} as Message);

  const columns: GridColDef[] = [
    {
      field: "created_on",
      flex: 2,
      headerName: t("history.date"),
      type: "dateTime",
      renderCell: (params: GridCellParams) => {
        return <>{new Date(params.value as string).toLocaleString()}</>;
      },
    },
    {
      field: "email_message",
      headerName: t("history.subject"),
      renderCell: (params: GridCellParams) => {
        let message = params.value as EmailMessage;
        return <>{(message && message.subject) || t("history.no_subject")}</>;
      },
      flex: 6,
    },
    {
      field: "id",
      headerName: t("history.details"),
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title="Details">
            <IconButton aria-label="details">
              <Icon>info</Icon>
            </IconButton>
          </Tooltip>
        );
      },
      flex: 1,
      align: "center",
      hideSortIcons: true,
      editable: false,
      disableColumnMenu: true,
    },
  ];

  function handleCellClick(params: GridCellParams) {
    if (params.colDef.field === "id") {
      let message = messages.find((message) => {
        return message.id === params.id;
      });

      if (message?.id) {
        setUpdatingMessage(true);
        messageStore
          .fetchMessage(message.id)
          .then((messageResponse) => {
            setSelectedMessage(messageResponse);
          })
          .catch((error) => {
            enqueueSnackbar(`${t("history.snackbar.message_status_error")}: ${error}`, {
              variant: "error",
            });
          })
          .finally(() => {
            setUpdatingMessage(false);
          });
        setSelectedMessage(message);
        setMessageDialog(true);
      }
    }
  }

  return (
    <div className={classes.container}>
      <h1>{t("history.title")}</h1>
      <div className={classes.table}>
        <DataGrid
          rows={messages}
          columns={columns}
          onCellClick={handleCellClick}
        />
      </div>
      {messageDiaglogOpen && (
        <HistoryDetailDialog
          message={selectedMessage}
          updating={updatingMessage}
          close={() => {
            setMessageDialog(false);
            setSelectedMessage({} as Message);
          }}
        />
      )}
    </div>
  );
}
