import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { Message, Recipient } from "../../services/messages/Message";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Modal,
  Paper,
  Switch,
} from "@material-ui/core";
import { ContactMail, ContactPhone } from '@material-ui/icons';
import { contactStore } from "../../services/contacts/ContactStore";
import { Contact } from "../../services/contacts/Contact";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  status: {
    maxHeight: "25rem"
  },
  body: {
    padding: "1rem",
    whiteSpace: "pre-wrap",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    width: "100%",
  },
  spinner: {
    float: "right",
    position: "absolute",
    top: 0,
    right: 0,
    margin: "1rem",
  },
  switch: {
    float: "right",
    margin: "1rem",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    padding: "1rem"
  }
});

function determineBody(message: Message): string {
  return (
    message.sms_message?.body ||
    message.email_message?.body ||
    message.call_message.body
  );
}

/* 
  This gives a warning with React Strictmode, should be fixed in version 5.0.
  Which hopefully realeases soon.
  https://github.com/mui-org/material-ui/milestones
*/
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HistoryDetailDialog(props: {
  message: Message;
  updating: boolean;
  close: () => void;
}) {
  const { t } = useTranslation();
  const MESSAGE_FAILED: string = 'FAILED';

  const classes = useStyles();
  const [failedOnly, setFailedOnly] = useState(false);
  const [email, setEmail] = useState(props.message.email_message?.recipients);
  const [phone, setPhone] = useState(props.message.call_message?.recipients);
  const [text, setText] = useState(props.message.sms_message?.recipients);

  function toggleFailedOnly(showFailed: boolean) {
    if (showFailed) {
      setEmail(props.message.email_message?.recipients.filter(recipient => {
        return (recipient.status === MESSAGE_FAILED);
      }));
      setPhone(props.message.call_message?.recipients.filter(recipient => {
        return (recipient.status === MESSAGE_FAILED);
      }));
      setText(props.message.sms_message?.recipients.filter(recipient => {
        return (recipient.status === MESSAGE_FAILED);
      }));
    } else {
      setEmail(props.message.email_message?.recipients);
      setPhone(props.message.call_message?.recipients);
      setText(props.message.sms_message?.recipients);
    }
    setFailedOnly(showFailed);
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={() => props.close()}
        TransitionComponent={Transition}
        scroll={"paper"}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={8}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {t("history.detail.title")}
              </Typography>
              {props.updating && (
                <CircularProgress size={30} className={classes.spinner} />
              )}
              <Typography variant="h5" component="h2">
                {(props.message.email_message &&
                  props.message.email_message.subject) ||
                  t("history.no_subject")}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                {new Date(props.message.created_on!).toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch
                    checked={failedOnly}
                    onChange={(event) => toggleFailedOnly(event.target.checked)}
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Typography
                    variant="caption"
                    color="textSecondary">
                    {t("history.detail.failed_only")}
                  </Typography>}
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Grid className={classes.status} container spacing={2}>
              {email && (
                <Grid item xs={4}>
                  <Typography variant="subtitle2">
                    {!failedOnly ?
                      `${t("history.detail.sent_mail")}:` :
                      `${t("history.detail.sent_mail")} ${t("history.detail.not_shown",
                        { amount: (props.message.email_message?.recipients.length - email.length).toString() })}: `}
                  </Typography>
                  <List dense>
                    {email.map((recipient, index) => {
                      return (
                        <HistoryDetailRecipientItem
                          key={`${recipient.first_name}${index}`}
                          recipient={recipient}
                          type={'email'} />
                      );
                    })}
                  </List>
                </Grid>
              )}

              {phone && (
                <Grid item xs={4}>
                  <Typography variant="subtitle2">
                    {!failedOnly ?
                      `${t("history.detail.sent_phone")}:` :
                      `${t("history.detail.sent_phone")} ${t("history.detail.not_shown",
                        { amount: (props.message.call_message?.recipients.length - phone.length).toString() })}: `}
                  </Typography>
                  <List dense>
                    {phone.map((recipient, index) => {
                      return (
                        <HistoryDetailRecipientItem
                          key={`${recipient.first_name}${index}`}
                          recipient={recipient}
                          type={'phone'} />
                      );
                    })}
                  </List>
                </Grid>
              )}

              {text && (
                <Grid item xs={4}>
                  <Typography variant="subtitle2">
                    {!failedOnly ?
                      `${t("history.detail.sent_text")}:` :
                      `${t("history.detail.sent_text")} ${t("history.detail.not_shown",
                        { amount: (props.message.sms_message?.recipients.length - text.length).toString() })}: `}
                  </Typography>
                  <List dense>
                    {text.map((recipient, index) => {
                      return (
                        <HistoryDetailRecipientItem
                          key={`${recipient.first_name}${index}`}
                          recipient={recipient}
                          type={'text'} />
                      );
                    })}
                  </List>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Paper elevation={0} className={classes.body}>
            <Typography variant="body2">
              {determineBody(props.message)}
            </Typography>
          </Paper>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function HistoryDetailRecipientItem(props: { recipient: Recipient, type: string }) {
  const MESSAGE_FAILED: string = 'FAILED';

  const [contact, setContact] = useState({} as any);
  const [modalOpen, setModalOpen] = useState(false);

  function showContactInfo() {
    const contact = contactStore.getContactById(props.recipient.contact_id) || undefined;
    setContact(contact);
    setModalOpen(true);
  }

  function closeContactInfo() {
    setModalOpen(false);
    setContact(null);
  }

  return (
    <ListItem>
      {modalOpen && <ContactModal contact={contact} close={closeContactInfo} />}

      <ListItemText
        primary={`${props.recipient.first_name} ${props.recipient.last_name}`}
        secondary={props.recipient.status}
      />
      {props.recipient.status === MESSAGE_FAILED && <ListItemSecondaryAction>
        <IconButton edge="end" onClick={showContactInfo}>
          {props.type === 'email' ? <ContactMail /> : <ContactPhone />}
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
  );
}

function ContactModal(props: { contact: Contact, close: () => void }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      onClose={props.close}
      className={classes.modal}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {t("history.contact.title")}
          </Typography>
          <Typography variant="h5" component="h2">
            {props.contact.first_name} {props.contact.last_name}
          </Typography>
          <Typography variant="body2" component="p">
            {props.contact.email && `${t("contacts.mail")}: ${props.contact.email}`}
            <br />
            {props.contact.telephone && `${t("contacts.phone")}: ${props.contact.telephone}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={props.close}>{t("close")}</Button>
        </CardActions>
      </Card>
    </Modal>
  );
}
