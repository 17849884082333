import { useEffect } from "react";
import { useState } from "react";
import { Group } from "./Group";
import { groupStore } from "./GroupStore";

interface GroupState {
  groups: Group[];
}

function useGroups(): GroupState {
  const [groups, setGroups] = useState<GroupState>({ groups: [] });

  useEffect(() => {
    const groupSubscription = groupStore.groups$.subscribe((newGroups) => {
      setGroups({ groups: newGroups });
    });
    return () => {
      groupSubscription.unsubscribe();
    };
  }, []);

  return groups;
}

export default useGroups;
